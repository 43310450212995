import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-17666f01"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 1 };
const _hoisted_2 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { key: 1 };
const _hoisted_7 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_8 = {
    key: 0,
    class: "d-flex align-items-center gap-3"
};
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AssignCarrierButton = _resolveComponent("AssignCarrierButton");
    const _component_DownloadSpecificationsButton = _resolveComponent("DownloadSpecificationsButton");
    const _component_Column = _resolveComponent("Column");
    const _component_TableCellLink = _resolveComponent("TableCellLink");
    const _component_Badge = _resolveComponent("Badge");
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_UserInitials = _resolveComponent("UserInitials");
    const _component_ExpandableTableCell = _resolveComponent("ExpandableTableCell");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "tenders-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                loading: _ctx.isDataLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                rowHover: false,
                selection: _ctx.selectedTenders,
                "onUpdate:selection": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedTenders) = $event)),
                first: _ctx.lazyParams.first,
                ref: "tendersList"
            }, {
                "header-buttons": _withCtx(() => [
                    (_ctx.roleCheck(_ctx.$permissions.canManageTenders) && _ctx.isLoady4Tender)
                        ? (_openBlock(), _createBlock(_component_AssignCarrierButton, {
                            key: 0,
                            selected: _ctx.selectedTenders,
                            onUpdate: _ctx.editTender
                        }, null, 8, ["selected", "onUpdate"]))
                        : _createCommentVNode("", true),
                    (_ctx.isLoady4Tender
                        ? _ctx.roleCheck(_ctx.$permissions.canManageTenders)
                        : _ctx.roleCheck([..._ctx.$permissions.canManageTenders, _ctx.$roles.companyAdmin, _ctx.$roles.dataManager]))
                        ? (_openBlock(), _createBlock(_component_DownloadSpecificationsButton, {
                            key: 1,
                            selectedIds: _ctx.selectedTenders.map((it) => it.id)
                        }, null, 8, ["selectedIds"]))
                        : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        selectionMode: "multiple",
                        class: "not-resizable-column",
                        style: { "min-width": "4rem" }
                    }),
                    _createVNode(_component_Column, {
                        field: "id",
                        header: _ctx.translate('columns.tender-id'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.id)
                                ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                    key: 0,
                                    values: [data.id],
                                    class: "text-wrap id",
                                    onClick: ($event) => (_ctx.goToPrefilteredTenderLanesList(data))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.id), 1)
                                    ]),
                                    _: 2
                                }, 1032, ["values", "onClick"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_1, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('referenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "referenceNumber",
                            header: _ctx.getColumnHeader('referenceNumber'),
                            filterMatchMode: "contains",
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.referenceNumber?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(data.referenceNumber), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.tender-name'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.name)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(data.name), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('start'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "start",
                            header: _ctx.getColumnHeader('start'),
                            dataType: "date",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.$utils.getDateValue(data.start, "DD-MM-YYYY") ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('end'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "end",
                            header: _ctx.getColumnHeader('end'),
                            dataType: "date",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.$utils.getDateValue(data.end, "DD-MM-YYYY") ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('contractStart'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "contractStart",
                            header: _ctx.getColumnHeader('contractStart'),
                            dataType: "date",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.$utils.getDateValue(data.contractStart, "DD-MM-YYYY") ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('contractEnd'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "contractEnd",
                            header: _ctx.getColumnHeader('contractEnd'),
                            dataType: "date",
                            style: { "min-width": "6rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.$utils.getDateValue(data.contractEnd, "DD-MM-YYYY") ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('status'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "status",
                            header: _ctx.getColumnHeader('status'),
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.status)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        color: _ctx.tenderStatusStyles[data.status]
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getValue(data.status, "TenderStatus")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["color"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('assignedCarriers'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            field: "assignedCarriers",
                            header: _ctx.getColumnHeader('assignedCarriers'),
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.assignedCarriers?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                        (data.assignedCarriers?.length === 1)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                (data.assignedCarriers[0]?.logoPath)
                                                    ? (_openBlock(), _createBlock(_component_Thumbnail, {
                                                        key: 0,
                                                        src: data.assignedCarriers[0]?.logoPath,
                                                        size: "lg"
                                                    }, null, 8, ["src"]))
                                                    : (_openBlock(), _createBlock(_component_UserInitials, {
                                                        key: 1,
                                                        string: data.assignedCarriers[0]?.name
                                                    }, null, 8, ["string"])),
                                                _createElementVNode("span", null, _toDisplayString(data.assignedCarriers[0]?.name), 1)
                                            ]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                                _createVNode(_component_ExpandableTableCell, null, {
                                                    entity: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.getCarriers(data.assignedCarriers)), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]))
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.roleCheck(_ctx.$permissions.canManageTenders) && _ctx.isLoady4Tender)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 7,
                            class: "context-menu-column not-resizable-column",
                            style: { "min-width": "6rem" },
                            alignFrozen: "right",
                            frozen: ""
                        }, {
                            body: _withCtx(({ data }) => [
                                _createVNode(_component_TableRowContextMenu, {
                                    data: data,
                                    contextTranslate: _ctx.translate,
                                    onUpdate: _ctx.delegate.editItem,
                                    onDelete: ($event) => (_ctx.deleteTender(data)),
                                    notEditable: _ctx.checkIsStatusClosed(data.status)
                                }, {
                                    items: _withCtx((contextMenuSlotProps) => [
                                        (!_ctx.checkIsStatusClosed(data.status))
                                            ? (_openBlock(), _createBlock(_component_MenuItem, {
                                                key: 0,
                                                label: _ctx.translate('components.tenders-list.row-context-menu.close'),
                                                onClick: () => _ctx.closeTender(data, contextMenuSlotProps),
                                                icon: "remove_circle"
                                            }, null, 8, ["label", "onClick"]))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["data", "contextTranslate", "onUpdate", "onDelete", "notEditable"])
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["value", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "selection", "first"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
