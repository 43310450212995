import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5ff62c6c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "resource-card" };
const _hoisted_2 = { class: "card" };
const _hoisted_3 = { class: "top-part" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "bottom-part" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_Button = _resolveComponent("Button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                    class: "resouces-imgs",
                    src: _ctx.imageUrl,
                    alt: ""
                }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.title), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1),
                _createVNode(_component_Button, {
                    class: "button",
                    onClick: _ctx.openLink
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.button) + " ", 1),
                        _createVNode(_component_Icon, {
                            class: "button-icon",
                            name: "arrow_forward"
                        })
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ])
        ])
    ]));
}
