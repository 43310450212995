import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayFieldChips = _resolveComponent("DisplayFieldChips");
    const _component_DisplaySection = _resolveComponent("DisplaySection");
    return (_openBlock(), _createBlock(_component_DisplaySection, null, {
        default: _withCtx(() => [
            _createVNode(_component_DisplayFieldChips, {
                name: "tradeNames",
                label: _ctx.translate('trade-names.label'),
                value: _ctx.model.tradeNames
            }, null, 8, ["label", "value"]),
            _createVNode(_component_DisplayFieldChips, {
                name: "synonyms",
                label: _ctx.translate('synonyms.label'),
                value: _ctx.model.synonyms
            }, null, 8, ["label", "value"])
        ]),
        _: 1
    }));
}
