import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "col-6" };
const _hoisted_3 = { class: "col-6" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayLabelField = _resolveComponent("DisplayLabelField");
    const _component_DisplayValueField = _resolveComponent("DisplayValueField");
    return (_openBlock(), _createElementBlock("div", {
        class: "row",
        "data-qa-id": _ctx.name
    }, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_DisplayLabelField, null, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
            })
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_DisplayValueField, null, {
                default: _withCtx(() => [
                    (Number.isFinite(_ctx.value))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
                            (_ctx.unit)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.unit), 1))
                                : _createCommentVNode("", true)
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                ]),
                _: 1
            })
        ])
    ], 8, _hoisted_1));
}
