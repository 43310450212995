import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-77f37b7d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "d-flex flex-column" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = { class: "d-flex flex-column" };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Column = _resolveComponent("Column");
    const _component_ExpandableTableCell = _resolveComponent("ExpandableTableCell");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_Badge = _resolveComponent("Badge");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "sites-table"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                onRowClick: _ctx.navigateTo,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                filterDisplay: "row",
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                ref: "sitesTable",
                loading: _ctx.isLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                first: _ctx.lazyParams.first
            }, {
                default: _withCtx(() => [
                    (_ctx.isColumnVisible('id'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "id",
                            header: _ctx.getColumnHeader('id'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.data.id ?? "-"), 1)
                            ]),
                            _: 2
                        }, 1032, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.name'),
                        style: { "min-width": "16rem" }
                    }, {
                        body: _withCtx((slotProps) => [
                            _createVNode(_component_ExpandableTableCell, {
                                class: "label",
                                entityName: slotProps.data.name,
                                entityId: slotProps.data.id,
                                iconName: "location_city"
                            }, null, 8, ["entityName", "entityId"])
                        ]),
                        _: 2
                    }, 1032, ["header"]),
                    (_ctx.isColumnVisible('referenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "referenceNumber",
                            header: _ctx.getColumnHeader('referenceNumber'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.referenceNumber?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(data.referenceNumber), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('city'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "city",
                            header: _ctx.getColumnHeader('city'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(data.city ?? "-"), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableCities,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.city')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('company'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "company",
                            header: _ctx.getColumnHeader('company'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(data.companyName ?? "-"), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                (_ctx.isPublicList)
                                    ? (_openBlock(), _createBlock(_component_AppMultiSelect, {
                                        key: 0,
                                        modelValue: filterModel.value,
                                        "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                        onChange: ($event) => (filterCallback()),
                                        options: _ctx.availableCompanies,
                                        optionLabel: "value",
                                        optionValue: "value",
                                        display: "chip",
                                        placeholder: _ctx.translate('columns.filters.company')
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('loadingPointCount'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "loadingPointCount",
                            header: _ctx.getColumnHeader('loadingPointCount'),
                            dataType: "numeric",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.loadingPointCount)
                                    ? (_openBlock(), _createBlock(_component_Badge, { key: 0 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.loadingPointCount), 1)
                                        ]),
                                        _: 2
                                    }, 1024))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingPointCount'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "unloadingPointCount",
                            header: _ctx.getColumnHeader('unloadingPointCount'),
                            dataType: "numeric",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.unloadingPointCount)
                                    ? (_openBlock(), _createBlock(_component_Badge, { key: 0 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.unloadingPointCount), 1)
                                        ]),
                                        _: 2
                                    }, 1024))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_5, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('updatedTime'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 7,
                            field: "updatedTime",
                            header: _ctx.getColumnHeader('updatedTime'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_8, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        class: "context-menu-column not-resizable-column",
                        style: { "min-width": "6rem" },
                        alignFrozen: "right",
                        frozen: ""
                    }, {
                        body: _withCtx((slotProps) => [
                            (_ctx.canCRUDEntity(slotProps.data.currentUserRole) && !_ctx.isPublicSitesList)
                                ? (_openBlock(), _createBlock(_component_TableRowContextMenu, {
                                    key: 0,
                                    onDelete: _ctx.delegate.deleteItem,
                                    data: slotProps.data,
                                    contextTranslate: _ctx.translate
                                }, null, 8, ["onDelete", "data", "contextTranslate"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 2
                    }, 1024)
                ]),
                _: 2
            }, 1032, ["value", "onRowClick", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "first"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
