import TendersListComponent from "@/app/domains/tenders/components/tenders-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { TendersDomainId, TenderListId, AssignCarrierModalId, CloseTenderModalId, DownloadSpecificationsModalId, } from "@/app/domains/tenders/tenders.constants";
import { SelectedTendersFilterService } from "@/app/domains/tenders/services/selected-tenders-filter.service";
import TenderModalComponent from "@/app/domains/tenders/components/tender-modal.component.vue";
import { Permissions } from "@/app/shared/constants/permissions.constants";
import { TendersService } from "@/app/domains/tenders/services/tenders.service";
import { ModalModule } from "@/app/modals/modules/modal.module";
import AssignCarrierModal from "@/app/domains/tenders/components/assign-carrier-modal.component.vue";
import CloseTenderModal from "@/app/domains/tenders/components/close-tender-modal.component.vue";
import DownloadSpecificationsModal from "@/app/domains/tenders/components/download-specifications-modal.component.vue";
export const TendersModule = createModule({
    components: [TendersListComponent, TenderModalComponent],
    imports: [
        DomainModule({
            id: TendersDomainId,
            icon: "svg/tenders-dark",
            detailsIcon: "svg/tenders-dark",
            loady4Tender: true,
            lists: [
                {
                    id: TenderListId,
                    default: true,
                    filter: SelectedTendersFilterService,
                    createRoles: Permissions.canManageTenders,
                },
            ],
            domainTranslationPrefix: "tenders",
            baseUrl: "tenders",
            components: {
                table: TendersListComponent,
                createModal: TenderModalComponent,
                editModal: TenderModalComponent,
            },
        }),
        ModalModule(AssignCarrierModalId, AssignCarrierModal),
        ModalModule(CloseTenderModalId, CloseTenderModal),
        ModalModule(DownloadSpecificationsModalId, DownloadSpecificationsModal),
    ],
    providers: [
        TendersService,
        SelectedTendersFilterService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.tenders",
        },
    ],
});
