import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ca978ef"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "legend mb-3" };
const _hoisted_3 = { class: "d-flex flex-column gap-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_ProcessesInputProcess = _resolveComponent("ProcessesInputProcess");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.internalModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.internalModel) = $event)),
            initial: _ctx.internalInitial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                (_ctx.internalModel)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("choose-applicable-processes")), 1),
                        _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalModel.processes, (process, index) => {
                                return (_openBlock(), _createBlock(_component_ProcessesInputProcess, {
                                    key: process.id,
                                    name: `processes[${index}]`,
                                    label: process.name,
                                    selected: process.selected
                                }, null, 8, ["name", "label", "selected"]));
                            }), 128))
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
