export const Resources = [
    {
        iconName: "quiz",
        variable: "faq",
        image: "questions.png",
    },
    {
        iconName: "info",
        variable: "training",
        image: "intro.png",
    },
    {
        iconName: "message",
        variable: "contact",
        image: "help.png",
    },
];
