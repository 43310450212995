import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { class: "d-flex flex-column" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { class: "d-flex flex-column" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AssignProductMasterButton = _resolveComponent("AssignProductMasterButton");
    const _component_Column = _resolveComponent("Column");
    const _component_PRLBadge = _resolveComponent("PRLBadge");
    const _component_ProductsTableCellProductName = _resolveComponent("ProductsTableCellProductName");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_RemoveProductMasterMenuItem = _resolveComponent("RemoveProductMasterMenuItem");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isInitialLoading || _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "assigned-products-table"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                ref: "assignedProductsTable",
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                selection: _ctx.selectedProducts,
                "onUpdate:selection": _cache[2] || (_cache[2] = ($event) => ((_ctx.selectedProducts) = $event)),
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                tableFilters: _ctx.filters,
                filterDisplay: "row",
                totalRecords: slotProps.data.count,
                first: _ctx.lazyParams.first,
                lazy: true,
                loading: _ctx.isInitialLoading || _ctx.isDataLoading,
                onPage: _cache[3] || (_cache[3] = ($event) => (_ctx.onPage($event))),
                onRowUnselect: _ctx.onRowUnselect,
                onRowClick: _ctx.onRowClick
            }, {
                "header-buttons": _withCtx(() => [
                    (_ctx.isAssignedProductsList && _ctx.hasWriteAccess && slotProps.data.results.length > 0)
                        ? (_openBlock(), _createBlock(_component_AssignProductMasterButton, {
                            key: 0,
                            products: _ctx.selectedProducts,
                            onAssign: _ctx.onProductMasterAssign
                        }, null, 8, ["products", "onAssign"]))
                        : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                    (_ctx.isAssignedProductsList && _ctx.hasWriteAccess)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            class: "not-resizable-column",
                            selectionMode: "multiple",
                            style: { "min-width": "4rem" }
                        }))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.name'),
                        style: { "min-width": "16rem" }
                    }, {
                        body: _withCtx((slotProps) => [
                            _createVNode(_component_ProductsTableCellProductName, {
                                product: slotProps.data
                            }, {
                                badge: _withCtx(() => [
                                    (slotProps.data.hasPrlRestrictions)
                                        ? (_openBlock(), _createBlock(_component_PRLBadge, {
                                            key: 0,
                                            productType: slotProps.data.productType,
                                            productId: slotProps.data.id,
                                            siteId: slotProps.data.siteId,
                                            loadingPointId: slotProps.data.loadingPointId ?? undefined,
                                            unloadingPointId: slotProps.data.unloadingPointId ?? undefined,
                                            managedByExternalCompany: slotProps.data.managedByExternalCompany
                                        }, null, 8, ["productType", "productId", "siteId", "loadingPointId", "unloadingPointId", "managedByExternalCompany"]))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 2
                            }, 1032, ["product"])
                        ]),
                        _: 2
                    }, 1032, ["header"]),
                    (_ctx.isColumnVisible('id'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "id",
                            header: _ctx.getColumnHeader('id'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.data.id ?? "-"), 1)
                            ]),
                            _: 2
                        }, 1032, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('referenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "referenceNumber",
                            header: _ctx.getColumnHeader('referenceNumber'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.referenceNumber?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(data.referenceNumber), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('productType'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "productType",
                            header: _ctx.getColumnHeader('productType'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.productTypeInfo(data.productType)), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.dictionaries.productType,
                                    optionLabel: "name",
                                    dictionaryId: "ProductType",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.product-type')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_3, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('updatedTime'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "updatedTime",
                            header: _ctx.getColumnHeader('updatedTime'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_6, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.hasWriteAccess)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            class: "context-menu-column not-resizable-column",
                            style: { "min-width": "6rem" },
                            alignFrozen: "right",
                            frozen: ""
                        }, {
                            body: _withCtx((slotProps) => [
                                _createVNode(_component_TableRowContextMenu, {
                                    data: slotProps.data,
                                    contextTranslate: _ctx.translate,
                                    onDelete: _ctx.onProductDelete
                                }, {
                                    items: _withCtx((contextMenuSlotProps) => [
                                        (_ctx.isAssignedProductsList && _ctx.isProductAssignedToProductMaster(contextMenuSlotProps.data))
                                            ? (_openBlock(), _createBlock(_component_RemoveProductMasterMenuItem, {
                                                key: 0,
                                                product: contextMenuSlotProps.data,
                                                onRemove: ($event) => (_ctx.onProductMasterRemove($event, contextMenuSlotProps))
                                            }, null, 8, ["product", "onRemove"]))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["data", "contextTranslate", "onDelete"])
                            ]),
                            _: 2
                        }, 1024))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["filters", "visibleColumns", "selection", "value", "columnsToggleDefault", "tableFilters", "totalRecords", "first", "loading", "onUpdate:visibleColumns", "onUpdate:filters", "onRowUnselect", "onRowClick"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
