import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-00cd0a80"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "col-6" };
const _hoisted_3 = { class: "col-6" };
const _hoisted_4 = { class: "d-flex flex-column gap-3" };
const _hoisted_5 = {
    key: 0,
    class: "description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayLabelField = _resolveComponent("DisplayLabelField");
    const _component_DisplayValueField = _resolveComponent("DisplayValueField");
    return (_openBlock(), _createElementBlock("div", {
        class: "row",
        "data-qa-id": _ctx.name
    }, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_DisplayLabelField, null, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
            })
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processes, (process) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: process.id,
                        class: "d-flex flex-column gap-1"
                    }, [
                        _createVNode(_component_DisplayValueField, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.dictionaryTranslate("Processes", process.id)), 1)
                            ]),
                            _: 2
                        }, 1024),
                        (process.additionalInformation)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(process.additionalInformation), 1))
                            : _createCommentVNode("", true)
                    ]));
                }), 128)),
                (_ctx.processes.length === 0)
                    ? (_openBlock(), _createBlock(_component_DisplayValueField, { key: 0 }, {
                        default: _withCtx(() => [
                            _createTextVNode("-")
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true)
            ])
        ])
    ], 8, _hoisted_1));
}
