import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3dc0e1f7"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "col-6" };
const _hoisted_3 = { class: "col-6" };
const _hoisted_4 = {
    key: 0,
    class: "chips"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayLabelField = _resolveComponent("DisplayLabelField");
    const _component_DisplayValueField = _resolveComponent("DisplayValueField");
    return (_openBlock(), _createElementBlock("div", {
        class: "row align-items-baseline",
        "data-qa-id": _ctx.name
    }, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_DisplayLabelField, null, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
            })
        ]),
        _createElementVNode("div", _hoisted_3, [
            (_ctx.value && _ctx.value.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: "chip",
                            "data-qa-id": "value"
                        }, [
                            _renderSlot(_ctx.$slots, "item", { item: item }, () => [
                                _createTextVNode(_toDisplayString(item), 1)
                            ], true)
                        ]));
                    }), 128))
                ]))
                : (_openBlock(), _createBlock(_component_DisplayValueField, { key: 1 }, {
                    default: _withCtx(() => [
                        _createTextVNode("-")
                    ]),
                    _: 1
                }))
        ])
    ], 8, _hoisted_1));
}
