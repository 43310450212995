import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditVapourReturnComponent from "@/app/sub-modules/vapour-return/components/edit-vapour-return.component.vue";
import DisplayVapourReturnComponent from "@/app/sub-modules/vapour-return/components/display-vapour-return.component.vue";
import DisplayVapourReturnViewComponent from "@/app/sub-modules/vapour-return/components/display-vapour-return-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const VapourReturnSubModule = (domainId, options = {}) => {
    const id = "vapourReturn";
    const moduleId = options.moduleId ?? TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditVapourReturnComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayVapourReturnComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayVapourReturnViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "repeat",
                showChangeHistory: options.showChangeHistory ?? true,
                configurationTranslationPrefix: "sub-modules.vapour-return",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.vapour-return",
            },
        ],
    });
};
