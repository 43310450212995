import { LoadingPointAssignedProductsDomainId } from "@/app/domains/assigned-products/assigned-products.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { ProductDomainId } from "@/app/domains/products/products.constants";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
export const SubModulesEditRouteName = "submodules-edit";
export const WebhooksRouteName = "webhooks-list";
export const WebhooksDomainId = "Webhooks";
export const CreateWebhookModalId = "CreateWebhook";
export const EditWebhookModalId = "EditEntityWebhooks";
export var WebhookTriggerType;
(function (WebhookTriggerType) {
    WebhookTriggerType["BPUnloadingPointOpeningHours"] = "BPUnloadingPointOpeningHours";
    WebhookTriggerType["SendLaneOnDemand"] = "SendLaneOnDemand";
    WebhookTriggerType["UnloadingPointUpdatesForYard"] = "UnloadingPointUpdatesForYard";
    WebhookTriggerType["LoadingPointUpdatesForYard"] = "LoadingPointUpdatesForYard";
})(WebhookTriggerType || (WebhookTriggerType = {}));
export const EntitySubModuleConfigurationMenuItems = [
    {
        domainId: SiteDomainId,
        id: "Site",
        route: `${SubModulesEditRouteName}/site`,
    },
    {
        domainId: LoadingPointDomainId,
        id: "LoadingPoint",
        route: `${SubModulesEditRouteName}/loading-point`,
    },
    {
        domainId: UnloadingPointDomainId,
        id: "UnloadingPoint",
        route: `${SubModulesEditRouteName}/unloading-point`,
    },
    {
        domainId: ProductDomainId,
        id: "Product",
        route: `${SubModulesEditRouteName}/product`,
    },
    {
        domainId: LoadingPointAssignedProductsDomainId,
        id: "AssignedProduct",
        route: `${SubModulesEditRouteName}/assigned-product`,
    },
];
export const EntityWebhooksConfigurationMenuItems = [
    {
        domainId: WebhooksDomainId,
        listId: "all",
        route: `${WebhooksRouteName}/all`,
    },
];
