import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-118e0776"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content-wrapper" };
const _hoisted_2 = { class: "content-column" };
const _hoisted_3 = { class: "pre-product-subtitle" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PageTitle = _resolveComponent("PageTitle");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_DisplayPreProduct = _resolveComponent("DisplayPreProduct");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_ctx.model)
        ? (_openBlock(), _createBlock(_component_Modal, {
            key: 0,
            showHeader: false,
            showCloseIcon: false,
            "modal-id": _ctx.modalId,
            class: "display-pre-product-modal"
        }, {
            footer: _withCtx(() => [
                _createVNode(_component_ButtonGroup, { class: "buttons-group" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_AppButton, {
                            class: "p-button-primary",
                            onClick: _ctx.modalService.close
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"])
                    ]),
                    _: 1
                })
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_PageHeader, { "with-subtitle": "" }, {
                            title: _withCtx(() => [
                                _createVNode(_component_PageTitle, { class: "m-0" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.model.name), 1)
                                    ]),
                                    _: 1
                                }),
                                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("sds-product-name")), 1)
                            ]),
                            buttons: _withCtx(() => [
                                _createVNode(_component_AppButton, {
                                    onClick: _ctx.openSDSLink,
                                    disabled: !_ctx.model.sdsLink
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("go-to-sds-button")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["onClick", "disabled"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_DisplayPreProduct, { model: _ctx.model }, null, 8, ["model"])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["modal-id"]))
        : _createCommentVNode("", true);
}
