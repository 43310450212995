import TendersListComponent from "@/app/domains/tenders/components/tenders-list.component.vue";
import TendersLanesListComponent from "@/app/domains/tenders-lanes/components/tenders-lanes-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { TendersCarriersDomainId, TendersListId, LanesListId, } from "@/app/domains/tenders-carriers/tenders-carriers.constants";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import { SelectedCarriersLanesFilterService } from "@/app/domains/tenders-carriers/services/selected-carriers-lanes.service";
export const TendersCarriersModule = createModule({
    components: [TendersListComponent, TendersLanesListComponent],
    imports: [
        DomainModule({
            id: TendersCarriersDomainId,
            icon: "svg/tenders-dark",
            detailsIcon: "svg/tenders-dark",
            forLogisticsServiceProvider: true,
            onlyForLogisticsServiceProvider: true,
            lists: [
                {
                    id: TendersListId,
                    default: true,
                    createRoles: [],
                },
                {
                    id: LanesListId,
                    component: TendersLanesListComponent,
                    filter: SelectedCarriersLanesFilterService,
                    createRoles: [],
                },
            ],
            domainTranslationPrefix: (routerService) => {
                if (routerService?.getMeta(RouteMetaListId) === TendersListId) {
                    return "tenders-carriers";
                }
                return "tender-carriers-lanes";
            },
            baseUrl: (routerService) => {
                if (routerService?.getMeta(RouteMetaListId) === TendersListId) {
                    return "tenders/carriers";
                }
                return "lanes/carriers";
            },
            components: {
                table: TendersListComponent,
            },
        }),
    ],
    providers: [
        SelectedCarriersLanesFilterService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.tenders-carriers",
        },
    ],
});
