import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayVehicleAndPPEChips = _resolveComponent("DisplayVehicleAndPPEChips");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            data: _ctx.model.minPPEToEnterSite,
            label: _ctx.translate('minimum-ppe-requirements.label'),
            anyChips: _ctx.model.minPPEToEnterSite?.length,
            editableDomain: ""
        }, null, 8, ["data", "label", "anyChips"]),
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            data: _ctx.model.additionalPPEForDGGoods,
            label: _ctx.translate('additional-ppe-requirements.label'),
            anyChips: _ctx.model.additionalPPEForDGGoods?.length,
            editableDomain: ""
        }, null, 8, ["data", "label", "anyChips"]),
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            data: _ctx.model.additionalULPSpecificPPErequirements,
            label: _ctx.translate('un-loading-point-specific-requirements.loading-label'),
            noChipsString: _ctx.translate('un-loading-point-specific-requirements.site-placeholder')
        }, null, 8, ["data", "label", "noChipsString"]),
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            data: _ctx.model.additionalAssignedProductSpecificPPErequirements,
            label: _ctx.translate('additional-product-ppe-requirements.label'),
            noChipsString: _ctx.translate('additional-product-ppe-requirements.loading-placeholder')
        }, null, 8, ["data", "label", "noChipsString"])
    ], 64));
}
