import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-086e153d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "extendable-columns-icon" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Icon, {
            class: _normalizeClass(["arrow", { rotate: _ctx.isOpen }]),
            name: "keyboard_double_arrow_right"
        }, null, 8, ["class"])
    ]));
}
