import { isString } from "lodash";
import { resolve } from "@/di/composables/resolve";
import { DictionaryTranslationService } from "@/app/translation/services/dictionary-translation.service";
export const useDictionaryTranslate = () => {
    const dictionaryTranslationService = resolve(DictionaryTranslationService);
    return (dictionaryId, dictionaryValueOrId = {}, param = "name") => {
        const dictionaryValueId = isString(dictionaryValueOrId) ? dictionaryValueOrId : dictionaryValueOrId.id;
        return dictionaryTranslationService.translate(`${dictionaryId}.${dictionaryValueId}.${param}`);
    };
};
export const useDictionariesTranslate = () => {
    const dictionaryTranslationService = resolve(DictionaryTranslationService);
    return (dictionaryIds, value = {}, param = "name", additionalParam) => {
        let translatedName = value[param];
        for (const dictionaryName of dictionaryIds) {
            const name = dictionaryTranslationService.translate(`${dictionaryName}.${value?.id}.${param}`);
            if (name.split(".").length === 1) {
                if (additionalParam) {
                    const additionalName = dictionaryTranslationService.translate(`${dictionaryName}.${value?.id}.${additionalParam}`);
                    translatedName = `${name} - ${additionalName}`;
                }
                else {
                    translatedName = name;
                }
                break;
            }
        }
        return translatedName;
    };
};
export const useDictionaryGroupTranslate = () => {
    const dictionaryTranslationService = resolve(DictionaryTranslationService);
    return (dictionaryGroup = {}, value = {}) => {
        const translatedName = dictionaryTranslationService.translate(`${dictionaryGroup?.dictionaryId}.${value?.dictionaryValueId}.name`);
        return translatedName;
    };
};
