import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3727b31f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "access-restriction-input" };
const _hoisted_2 = { class: "input-container" };
const _hoisted_3 = { class: "title required" };
const _hoisted_4 = { class: "max" };
const _hoisted_5 = { class: "label" };
const _hoisted_6 = ["src"];
const _hoisted_7 = { class: "input-field" };
const _hoisted_8 = { class: "unit" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("max")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.label), 1)
            ]),
            _createElementVNode("img", {
                class: "image",
                src: _ctx.imageUrl,
                alt: ""
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_InputNumber, {
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                    name: _ctx.name,
                    placeholder: _ctx.placeholder,
                    maxLength: 6,
                    maxIntegerDigits: 3,
                    maxFractionDigits: 2,
                    disabled: _ctx.disabled
                }, null, 8, ["modelValue", "name", "placeholder", "disabled"]),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.unit), 1)
            ]),
            (!_ctx.disabled)
                ? (_openBlock(), _createBlock(_component_FormError, {
                    key: 0,
                    name: _ctx.name
                }, null, 8, ["name"]))
                : _createCommentVNode("", true)
        ])
    ]));
}
