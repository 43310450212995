import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/logo.svg';
const _withScopeId = n => (_pushScopeId("data-v-555cad46"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "logo",
    src: _imports_0,
    alt: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("img", _hoisted_1));
}
