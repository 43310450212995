import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconSvg = _resolveComponent("IconSvg");
    const _component_IconFont = _resolveComponent("IconFont");
    return (_ctx.isSvg)
        ? (_openBlock(), _createBlock(_component_IconSvg, {
            key: 0,
            name: _ctx.svgName
        }, null, 8, ["name"]))
        : (_openBlock(), _createBlock(_component_IconFont, {
            key: 1,
            name: _ctx.name
        }, null, 8, ["name"]));
}
