import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-13e2cf48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "text-wrap name"
};
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { class: "name-id" };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "d-flex align-items-center gap-3 justify-content-between" };
const _hoisted_6 = { class: "name-id" };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_12 = { key: 1 };
const _hoisted_13 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_14 = { key: 1 };
const _hoisted_15 = { key: 1 };
const _hoisted_16 = { key: 1 };
const _hoisted_17 = { key: 1 };
const _hoisted_18 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_19 = { key: 1 };
const _hoisted_20 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_21 = { key: 1 };
const _hoisted_22 = { key: 1 };
const _hoisted_23 = { class: "d-flex align-items-center gap-3 justify-content-between" };
const _hoisted_24 = { class: "name-id" };
const _hoisted_25 = { key: 1 };
const _hoisted_26 = { key: 1 };
const _hoisted_27 = { key: 1 };
const _hoisted_28 = { class: "d-flex flex-column" };
const _hoisted_29 = { key: 0 };
const _hoisted_30 = { key: 1 };
const _hoisted_31 = { class: "d-flex flex-column" };
const _hoisted_32 = { key: 0 };
const _hoisted_33 = { key: 1 };
const _hoisted_34 = { key: 1 };
const _hoisted_35 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_36 = {
    key: 0,
    class: "d-flex align-items-center gap-3"
};
const _hoisted_37 = { key: 1 };
const _hoisted_38 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AssignPartnersButton = _resolveComponent("AssignPartnersButton");
    const _component_ShareButton = _resolveComponent("ShareButton");
    const _component_Column = _resolveComponent("Column");
    const _component_ExpandableTableCell = _resolveComponent("ExpandableTableCell");
    const _component_TableCellLink = _resolveComponent("TableCellLink");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_PRLBadge = _resolveComponent("PRLBadge");
    const _component_Badge = _resolveComponent("Badge");
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_UserInitials = _resolveComponent("UserInitials");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isInitialLoading || _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "lanes-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                filterDisplay: "row",
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                loading: _ctx.isInitialLoading || _ctx.isDataLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                rowHover: false,
                selection: _ctx.selectedLanes,
                "onUpdate:selection": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedLanes) = $event)),
                first: _ctx.lazyParams.first,
                ref: "lanesList"
            }, {
                "header-buttons": _withCtx(() => [
                    (_ctx.hasEnhancedRights)
                        ? (_openBlock(), _createBlock(_component_AssignPartnersButton, {
                            key: 0,
                            "selected-lanes": _ctx.selectedLanes.map((lane) => lane.id),
                            onUpdate: _ctx.delegate.editItems
                        }, null, 8, ["selected-lanes", "onUpdate"]))
                        : _createCommentVNode("", true),
                    (_ctx.roleCheck(_ctx.$permissions.belongsToCompany))
                        ? (_openBlock(), _createBlock(_component_ShareButton, {
                            key: 1,
                            "selected-lanes": _ctx.selectedLanes.map((lane) => lane.id),
                            isLanesWebhookEnabled: _ctx.isLanesWebhookEnabled
                        }, null, 8, ["selected-lanes", "isLanesWebhookEnabled"]))
                        : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        selectionMode: "multiple",
                        class: "not-resizable-column",
                        style: { "min-width": "4rem" }
                    }),
                    _createVNode(_component_Column, {
                        field: "id",
                        header: _ctx.translate('columns.lane-id'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(data.id), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('companyFrom'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "companyFrom",
                            header: _ctx.getColumnHeader('companyFrom'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_3, [
                                    (data.companyFromName)
                                        ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                            key: 0,
                                            values: [data.companyFromId],
                                            class: "name",
                                            onClick: ($event) => (_ctx.linkService.goToCompany(data.companyFromId))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_ExpandableTableCell, {
                                                    entityName: data.companyFromName
                                                }, null, 8, ["entityName"])
                                            ]),
                                            _: 2
                                        }, 1032, ["values", "onClick"]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                                ])
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableCompaniesFrom,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.company-from')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('product'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "product",
                            header: _ctx.getColumnHeader('product'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, [
                                        (data.productName)
                                            ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                                key: 0,
                                                values: [data.productId],
                                                onClick: ($event) => (_ctx.linkService.goToLoadedProduct(data.companyFromId, data.siteFromId, data.loadingPointId, data.productId))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ExpandableTableCell, {
                                                        entityName: data.productName
                                                    }, null, 8, ["entityName"])
                                                ]),
                                                _: 2
                                            }, 1032, ["values", "onClick"]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                                    ]),
                                    (data.productHasPrlRestrictions)
                                        ? (_openBlock(), _createBlock(_component_PRLBadge, {
                                            key: 0,
                                            productType: data.productType,
                                            productId: data.productId,
                                            siteId: data.siteFromId,
                                            loadingPointId: data.loadingPointId,
                                            managedByExternalCompany: data.productManagedByExternalCompany
                                        }, null, 8, ["productType", "productId", "siteId", "loadingPointId", "managedByExternalCompany"]))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableProducts,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.product')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('productType'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "productType",
                            header: _ctx.getColumnHeader('productType'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.getValue(data.productType, _ctx.dictionaries.productType, "ProductType")), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.dictionaries.productType,
                                    optionLabel: "name",
                                    dictionaryId: "ProductType",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.product-type')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('productAuthorizationGroup'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "productAuthorizationGroup",
                            header: _ctx.getColumnHeader('productAuthorizationGroup'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.productAuthorizationGroup?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(data.productAuthorizationGroup), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableProductAuthorizationGroup,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.authorization-group')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteFrom'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "siteFrom",
                            header: _ctx.getColumnHeader('siteFrom'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteFromName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.siteFromId],
                                        onClick: ($event) => (_ctx.linkService.goToEntity(data.companyFromId, _ctx.SiteDomainId, data.siteFromId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.siteFromName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSitesFrom,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-from')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteFromCity'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "siteFromCity",
                            header: _ctx.getColumnHeader('siteFromCity'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteFromCity?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(data.siteFromCity), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSitesFromCities,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-from-city')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteFromPostalCode'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            field: "siteFromPostalCode",
                            header: _ctx.getColumnHeader('siteFromPostalCode'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteFromPostalCode?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(data.siteFromPostalCode), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_14, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSitesFromPostalCodes,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-from-postal-code')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteFromCountry'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 7,
                            field: "siteFromCountry",
                            header: _ctx.getColumnHeader('siteFromCountry'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.getValue(data.siteFromCountry, _ctx.dictionaries.country, "Country")), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableCountriesDictionary(_ctx.availableSitesFromCountries),
                                    optionLabel: "name",
                                    dictionaryId: "Country",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-from-country')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('loadingPoint'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 8,
                            field: "loadingPoint",
                            header: _ctx.getColumnHeader('loadingPoint'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.loadingPointName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.loadingPointId, data.siteFromId],
                                        onClick: ($event) => (_ctx.linkService.goToEntity(data.companyFromId, _ctx.LoadingPointDomainId, data.loadingPointId, _ctx.SiteDomainId, data.siteFromId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.loadingPointName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_15, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableLoadingPoints,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.loading-point')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('companyTo'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 9,
                            field: "companyTo",
                            header: _ctx.getColumnHeader('companyTo'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.companyToName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.companyToId],
                                        class: "name",
                                        onClick: ($event) => (_ctx.linkService.goToCompany(data.companyToId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.companyToName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableCompaniesTo,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.company-to')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteTo'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 10,
                            field: "siteTo",
                            header: _ctx.getColumnHeader('siteTo'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteToName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.siteToId],
                                        onClick: ($event) => (_ctx.linkService.goToEntity(data.companyToId, _ctx.SiteDomainId, data.siteToId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.siteToName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_17, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSitesTo,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-to')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteToCity'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 11,
                            field: "siteToCity",
                            header: _ctx.getColumnHeader('siteToCity'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteToCity?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(data.siteToCity), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_19, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSitesToCities,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-to-city')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteToPostalCode'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 12,
                            field: "siteToPostalCode",
                            header: _ctx.getColumnHeader('siteToPostalCode'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteToPostalCode?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(data.siteToPostalCode), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_21, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSitesToPostalCodes,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-to-postal-code')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteToCountry'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 13,
                            field: "siteToCountry",
                            header: _ctx.getColumnHeader('siteToCountry'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.getValue(data.siteToCountry, _ctx.dictionaries.country, "Country")), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableCountriesDictionary(_ctx.availableSitesToCountries),
                                    optionLabel: "name",
                                    dictionaryId: "Country",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site-to-country')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingPoint'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 14,
                            field: "unloadingPoint",
                            header: _ctx.getColumnHeader('unloadingPoint'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.unloadingPointName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.unloadingPointId, data.siteToId],
                                        onClick: ($event) => (_ctx.linkService.goToEntity(data.companyToId, _ctx.UnloadingPointDomainId, data.unloadingPointId, _ctx.SiteDomainId, data.siteToId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.unloadingPointName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_22, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableUnloadingPoints,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.unloading-point')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadedProduct'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 15,
                            field: "unloadedProduct",
                            header: _ctx.getColumnHeader('unloadedProduct'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("div", _hoisted_24, [
                                        (data.unloadedProductName)
                                            ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                                key: 0,
                                                values: [data.unloadedProductId],
                                                onClick: ($event) => (_ctx.linkService.goToUnloadedProduct(data.companyToId, data.siteToId, data.unloadingPointId, data.unloadedProductId))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ExpandableTableCell, {
                                                        entityName: data.unloadedProductName
                                                    }, null, 8, ["entityName"])
                                                ]),
                                                _: 2
                                            }, 1032, ["values", "onClick"]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_25, "-"))
                                    ]),
                                    (data.unloadedProductHasPrlRestrictions)
                                        ? (_openBlock(), _createBlock(_component_PRLBadge, {
                                            key: 0,
                                            productType: data.unloadedProductType,
                                            productId: data.unloadedProductId,
                                            siteId: data.siteToId,
                                            unloadingPointId: data.unloadingPointId,
                                            managedByExternalCompany: data.unloadedProductManagedByExternalCompany
                                        }, null, 8, ["productType", "productId", "siteId", "unloadingPointId", "managedByExternalCompany"]))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableUnloadedProducts,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.unloaded-product')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('loadingSummary'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 16,
                            field: "loadingSummary",
                            header: _ctx.getColumnHeader('loadingSummary'),
                            style: { "min-width": "6rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (!!data.loadingPointId && !!data.productId)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.loadingPointId, data.productId],
                                        onClick: ($event) => (_ctx.linkService.goToLoadingSummary(data.companyFromId, data.siteFromId, data.loadingPointId, data.productId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("phrases.link")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_26, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingSummary'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 17,
                            field: "unloadingSummary",
                            header: _ctx.getColumnHeader('unloadingSummary'),
                            style: { "min-width": "6rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (!!data.unloadingPointId && !!data.unloadedProductId)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.unloadingPointId, data.unloadedProductId],
                                        onClick: ($event) => (_ctx.linkService.goToUnloadingSummary(data.companyToId, data.siteToId, data.unloadingPointId, data.unloadedProductId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("phrases.link")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_27, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 18,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_28, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_30, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('lastUpdate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 19,
                            field: "lastUpdate",
                            header: _ctx.getColumnHeader('lastUpdate'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_31, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_33, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('validFrom'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 20,
                            field: "validFrom",
                            header: _ctx.getColumnHeader('validFrom'),
                            dataType: "date",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.$utils.getDateValue(data.validFrom, "DD-MM-YYYY") ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('validTo'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 21,
                            field: "validTo",
                            header: _ctx.getColumnHeader('validTo'),
                            dataType: "date",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.$utils.getDateValue(data.validTo, "DD-MM-YYYY") ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('status'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 22,
                            field: "status",
                            header: _ctx.getColumnHeader('status'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.status)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        color: _ctx.isCompleteStatus(data.status) ? 'green' : 'gray'
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getValue(data.status, _ctx.dictionaries.laneStatus, "LaneStatus")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["color"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_34, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.dictionaries.laneStatus,
                                    optionLabel: "name",
                                    dictionaryId: "LaneStatus",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.status')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('assignments'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 23,
                            field: "assignments",
                            header: _ctx.getColumnHeader('assignments'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.assignments?.length)
                                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_35, [
                                        (data.assignments?.length === 1)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                                (data.assignments[0]?.logoPath)
                                                    ? (_openBlock(), _createBlock(_component_Thumbnail, {
                                                        key: 0,
                                                        src: data.assignments[0]?.logoPath,
                                                        size: "lg"
                                                    }, null, 8, ["src"]))
                                                    : (_openBlock(), _createBlock(_component_UserInitials, {
                                                        key: 1,
                                                        string: data.assignments[0]?.name
                                                    }, null, 8, ["string"])),
                                                _createElementVNode("span", null, _toDisplayString(data.assignments[0]?.name), 1)
                                            ]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_37, [
                                                _createVNode(_component_ExpandableTableCell, null, {
                                                    entity: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.getNames(data.assignments)), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]))
                                    ])), [
                                        [
                                            _directive_tooltip,
                                            _ctx.getValidityRangeTooltip(data),
                                            void 0,
                                            { bottom: true }
                                        ]
                                    ])
                                    : (_openBlock(), _createElementBlock("div", _hoisted_38, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableAssignments,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.assignments')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.hasEnhancedRights)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 24,
                            class: "context-menu-column not-resizable-column",
                            style: { "min-width": "6rem" },
                            alignFrozen: "right",
                            frozen: ""
                        }, {
                            body: _withCtx((slotProps) => [
                                _createVNode(_component_TableRowContextMenu, {
                                    data: slotProps.data,
                                    contextTranslate: _ctx.translate,
                                    onUpdate: _ctx.delegate.editItem,
                                    onDelete: ($event) => (_ctx.deleteLane(slotProps.data))
                                }, null, 8, ["data", "contextTranslate", "onUpdate", "onDelete"])
                            ]),
                            _: 2
                        }, 1024))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["value", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "selection", "first"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
