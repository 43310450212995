import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditCompressorComponent from "@/app/sub-modules/compressor/components/edit-compressor.component.vue";
import DisplayCompressorComponent from "@/app/sub-modules/compressor/components/display-compressor.component.vue";
import DisplayCompressorViewComponent from "@/app/sub-modules/compressor/components/display-compressor-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const CompressorSubModule = (domainId, options = {}) => {
    const id = "compressor";
    const moduleId = options.moduleId ?? TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditCompressorComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayCompressorComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayCompressorViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "compress",
                showChangeHistory: options.showChangeHistory ?? true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.compressor",
            },
        ],
    });
};
