import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import EditProcesses from "@/app/sub-modules/processes/components/edit-processes.component.vue";
import DisplayProcesses from "@/app/sub-modules/processes/components/display-processes.component.vue";
import DisplayProcessesView from "@/app/sub-modules/processes/components/display-processes-view.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { ProcessesSubModuleId } from "@/app/sub-modules/processes/processes.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const ProcessesSubModule = (domainId, options = {}) => {
    const id = options.id ?? ProcessesSubModuleId;
    const moduleId = options.moduleId ?? GeneralInformationModuleId;
    const suffix = (component) => suffixComponentName(component, domainId, moduleId, id);
    const editComponent = suffix(EditProcesses);
    const displayComponent = suffix(DisplayProcesses);
    const displayViewComponent = suffix(DisplayProcessesView);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "apps",
                publicInformation: options.publicInformation,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.processes" }],
    });
};
