import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4936134c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "access-restriction" };
const _hoisted_2 = { class: "restriction-container" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, _toDisplayString(_ctx.translate("max")), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.label), 1)
            ]),
            _createElementVNode("img", {
                class: "image",
                src: _ctx.imageUrl,
                alt: ""
            }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.value) + " " + _toDisplayString(_ctx.unit), 1)
    ]));
}
