export var AuthenticationType;
(function (AuthenticationType) {
    AuthenticationType["Hmac"] = "Hmac";
    AuthenticationType["Oauth"] = "Oauth";
})(AuthenticationType || (AuthenticationType = {}));
export var WebhookTriggerScopeType;
(function (WebhookTriggerScopeType) {
    WebhookTriggerScopeType["EntireCompany"] = "EntireCompany";
    WebhookTriggerScopeType["LimitedSites"] = "LimitedSites";
})(WebhookTriggerScopeType || (WebhookTriggerScopeType = {}));
