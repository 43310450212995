import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row align-items-center" };
const _hoisted_2 = { class: "col-2" };
const _hoisted_3 = { class: "input" };
const _hoisted_4 = { class: "col-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_AppCheckbox, {
                    name: `${_ctx.name}.selected`,
                    label: _ctx.label
                }, null, 8, ["name", "label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_InputText, {
                name: `${_ctx.name}.additionalInformation`,
                placeholder: _ctx.translate('global.phrases.additional-information'),
                maxLength: 50,
                disabled: !_ctx.selected
            }, null, 8, ["name", "placeholder", "disabled"]),
            _createVNode(_component_FormError, {
                name: `${_ctx.name}.additionalInformation`
            }, null, 8, ["name"])
        ])
    ]));
}
