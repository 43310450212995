import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-670c5616"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "token-label" };
const _hoisted_3 = { class: "input-token" };
const _hoisted_4 = ["disabled", "placeholder"];
const _hoisted_5 = {
    key: 0,
    class: "error-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", {
            class: _normalizeClass(_ctx.containerClass),
            "data-qa-id": _ctx.$options.name
        }, [
            _createElementVNode("div", {
                ref: "wrapper",
                class: _normalizeClass(['wrapper', { disabled: _ctx.$props.disabled, focus: _ctx.focused }, _ctx.isRequired ? 'error' : 'border']),
                onClick: _cache[8] || (_cache[8] =
                    //@ts-ignore
                    (...args) => (_ctx.onWrapperClick && _ctx.onWrapperClick(...args)))
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (val, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: `${i}_${val}`,
                        class: _normalizeClass([_ctx.tokenClass(i), "token"]),
                        "data-qa-id": "value"
                    }, [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(val), 1),
                        _createVNode(_component_Icon, {
                            class: "token-icon",
                            name: "close",
                            "data-qa-id": "remove-button",
                            onClick: ($event) => (_ctx.removeItem(i))
                        }, null, 8, ["onClick"])
                    ], 2));
                }), 128)),
                _createElementVNode("div", _hoisted_3, [
                    _withDirectives(_createElementVNode("input", {
                        ref: "inputText",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.inputValue) = $event)),
                        disabled: _ctx.$props.disabled,
                        placeholder: _ctx.$props.placeholder,
                        "data-qa-id": "input",
                        onBlur: _cache[1] || (_cache[1] =
                            //@ts-ignore
                            (...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
                        onFocus: _cache[2] || (_cache[2] =
                            //@ts-ignore
                            (...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
                        onKeypress: _cache[3] || (_cache[3] = ($event) => (_ctx.keyEvent($event))),
                        onPaste: _cache[4] || (_cache[4] =
                            //@ts-ignore
                            (...args) => (_ctx.onPaste && _ctx.onPaste(...args))),
                        onKeyup: [
                            _cache[5] || (_cache[5] = _withKeys(
                            //@ts-ignore
                            (...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
                            _cache[6] || (_cache[6] = _withKeys(
                            //@ts-ignore
                            (...args) => (_ctx.onDelete && _ctx.onDelete(...args)), ["delete"])),
                            _cache[7] || (_cache[7] = _withKeys(
                            //@ts-ignore
                            (...args) => (_ctx.onEsc && _ctx.onEsc(...args)), ["esc"]))
                        ]
                    }, null, 40, _hoisted_4), [
                        [_vModelText, _ctx.inputValue]
                    ])
                ])
            ], 2)
        ], 10, _hoisted_1),
        (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.translate("validation.messages.required")), 1))
            : _createCommentVNode("", true)
    ], 64));
}
