var _a;
import { __decorate, __metadata } from "tslib";
import { chunk } from "lodash";
import { paramCase } from "change-case";
import { service } from "@/di/decorators/service.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { applyRouteUrlParams } from "@/app/modular-content/functions/route-url-params.function";
import { createEntityIdRouteParam } from "@/app/modular-content/functions/create-entity-id-route-param.function";
import { createRouteId } from "@/app/modular-content/functions/create-route-id";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { LanesDomainId } from "@/app/domains/lanes/lanes.constants";
import { LoadingPointAssignedProductsDomainId, UnloadingPointAssignedProductsDomainId, } from "@/app/domains/assigned-products/assigned-products.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { LoadingSummaryProductsDomainId, UnloadingSummaryProductsDomainId, ArchivedLoadingSummaryDomainId, ArchivedUnloadingSummaryDomainId, } from "@/app/domains/summary/summary.constants";
import { ManageCompanyAccountRouteName } from "@/app/domains/public-data/companies/company-overview/company-account/company-account.constants";
import { PreloadingRestrictionsDomainId } from "@/app/domains/preloading-restrictions/preloading-restrictions.constants";
import { ProductDomainId } from "@/app/domains/products/products.constants";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
import { UnloadingPointAssignedProductMastersDomainId } from "@/app/domains/assigned-product-masters/assigned-product-masters.constants";
let EntityLinkService = class EntityLinkService {
    routerService;
    constructor(routerService) {
        this.routerService = routerService;
    }
    goToCompany(companyId) {
        const url = this.routerService.getNamedRoute(ManageCompanyAccountRouteName)?.path.replace(":companyId", companyId);
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToEntity(companyId, domainId, entityId, ...parents) {
        this.goToEntityModule(companyId, GeneralInformationModuleId, domainId, entityId, ...parents);
    }
    goToEntityModule(companyId, moduleId, domainId, entityId, ...parents) {
        const entityLocation = [domainId, entityId];
        const parentLocations = chunk(parents, 2);
        const params = this.convertLocationsToRouteParams(entityLocation, ...parentLocations);
        const url = this.getEntityUrl(domainId, moduleId, params);
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToEntityList(entityListId, entityDomainId, parentDomainId, parentId) {
        let url = `/${createRouteId(entityDomainId)}/${entityListId}`;
        if (parentId && parentDomainId) {
            url = `/${createRouteId(parentDomainId)}/${parentId}/${createRouteId(entityDomainId)}/${entityListId}`;
        }
        window.open(url, "_blank");
    }
    goToPrefilteredEntityList(query, entityListId, entityDomainId, parentDomainId, parentId) {
        let url = `/${createRouteId(entityDomainId)}/${entityListId}`;
        if (parentId && parentDomainId) {
            url = `/${createRouteId(parentDomainId)}/${parentId}/${createRouteId(entityDomainId)}/${entityListId}`;
        }
        const route = this.routerService.getRoute({ path: url, query });
        window.open(route.href, "_blank");
    }
    goToLoadedProduct(companyId, siteId, loadingPointId, productId) {
        const sites = paramCase(SiteDomainId);
        const loadingPoint = paramCase(LoadingPointDomainId);
        const loadedProducts = paramCase(LoadingPointAssignedProductsDomainId);
        const url = `/${sites}/${siteId}/${loadingPoint}/${loadingPointId}/${loadedProducts}/${productId}`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToUnloadedProduct(companyId, siteId, unloadingPointId, productId, moduleId) {
        const sites = paramCase(SiteDomainId);
        const unloadingPoint = paramCase(UnloadingPointDomainId);
        const unloadedProducts = paramCase(UnloadingPointAssignedProductsDomainId);
        const modulePath = moduleId ? "/" + paramCase(moduleId) : "";
        const url = `/${sites}/${siteId}/${unloadingPoint}/${unloadingPointId}/${unloadedProducts}/${productId}${modulePath}`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToUnloadedProductMaster(companyId, siteId, unloadingPointId, productMasterId, moduleId) {
        const sites = paramCase(SiteDomainId);
        const unloadingPoint = paramCase(UnloadingPointDomainId);
        const unloadedProductMasters = paramCase(UnloadingPointAssignedProductMastersDomainId);
        const modulePath = moduleId ? "/" + paramCase(moduleId) : "";
        const url = `/${sites}/${siteId}/${unloadingPoint}/${unloadingPointId}/${unloadedProductMasters}/${productMasterId}${modulePath}`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToLoadingSummary(companyId, siteId, loadingPointId, productId) {
        const sites = paramCase(SiteDomainId);
        const loadingPoint = paramCase(LoadingPointDomainId);
        const loadedProducts = paramCase(LoadingPointAssignedProductsDomainId);
        const loadingSummary = paramCase(LoadingSummaryProductsDomainId);
        const url = `/${sites}/${siteId}/${loadingPoint}/${loadingPointId}/${loadedProducts}/${productId}/${loadingSummary}/site`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToUnloadingSummary(companyId, siteId, unloadingPointId, productId) {
        const sites = paramCase(SiteDomainId);
        const unloadingPoint = paramCase(UnloadingPointDomainId);
        const unloadedProducts = paramCase(UnloadingPointAssignedProductsDomainId);
        const unloadingSummary = paramCase(UnloadingSummaryProductsDomainId);
        const url = `/${sites}/${siteId}/${unloadingPoint}/${unloadingPointId}/${unloadedProducts}/${productId}/${unloadingSummary}/site`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToArchivedLoadingSummary(companyId, laneId) {
        const lanes = paramCase(LanesDomainId);
        const ArchivedLoadingSummary = paramCase(ArchivedLoadingSummaryDomainId);
        const url = `/${lanes}/${laneId}/${ArchivedLoadingSummary}/site`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToArchivedUnloadingSummary(companyId, laneId) {
        const lanes = paramCase(LanesDomainId);
        const ArchivedUnloadingSummary = paramCase(ArchivedUnloadingSummaryDomainId);
        const url = `/${lanes}/${laneId}/${ArchivedUnloadingSummary}/site`;
        const route = this.routerService.getRoute({ path: url, query: { companyId } });
        window.open(route.href, "_blank");
    }
    goToDefaultPRLList(productId) {
        const products = paramCase(ProductDomainId);
        const preloadingRestrictions = paramCase(PreloadingRestrictionsDomainId);
        const url = `/${products}/${productId}/${preloadingRestrictions}/all`;
        const route = this.routerService.getRoute({ path: url });
        window.open(route.href, "_blank");
    }
    goToLoadingPRLList(productId, siteId, loadingPointId) {
        const sites = paramCase(SiteDomainId);
        const loadingPoint = paramCase(LoadingPointDomainId);
        const loadingProducts = paramCase(LoadingPointAssignedProductsDomainId);
        const preloadingRestrictions = paramCase(PreloadingRestrictionsDomainId);
        const url = `/${sites}/${siteId}/${loadingPoint}/${loadingPointId}/${loadingProducts}/${productId}/${loadingProducts}-${preloadingRestrictions}/all`;
        const route = this.routerService.getRoute({ path: url });
        window.open(route.href, "_blank");
    }
    goToUnloadingPRLList(productId, siteId, unloadingPointId) {
        const sites = paramCase(SiteDomainId);
        const unloadingPoint = paramCase(UnloadingPointDomainId);
        const unloadingProducts = paramCase(UnloadingPointAssignedProductsDomainId);
        const preloadingRestrictions = paramCase(PreloadingRestrictionsDomainId);
        const url = `/${sites}/${siteId}/${unloadingPoint}/${unloadingPointId}/${unloadingProducts}/${productId}/${unloadingProducts}-${preloadingRestrictions}/all`;
        const route = this.routerService.getRoute({ path: url });
        window.open(route.href, "_blank");
    }
    convertLocationsToRouteParams(...locations) {
        return locations.reduce((routeParams, [domainId, entityId]) => {
            routeParams[createEntityIdRouteParam(domainId)] = entityId;
            return routeParams;
        }, {});
    }
    getEntityUrl(domainId, moduleId, routeParams) {
        const route = this.routerService.getRouteDetailsRecordByMetaData(domainId, moduleId);
        if (route && route.path) {
            return applyRouteUrlParams(route.path, routeParams);
        }
    }
};
EntityLinkService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], EntityLinkService);
export { EntityLinkService };
