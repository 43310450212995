import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7ac5b65d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "center-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconSvg = _resolveComponent("IconSvg");
    return (_ctx.center)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_IconSvg, {
                class: "loading-spinner",
                name: "loading-spinner"
            })
        ]))
        : (_openBlock(), _createBlock(_component_IconSvg, {
            key: 1,
            class: "loading-spinner",
            name: "loading-spinner"
        }));
}
