import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditPumpComponent from "@/app/sub-modules/pump/components/edit-pump.component.vue";
import DisplayPumpComponent from "@/app/sub-modules/pump/components/display-pump.component.vue";
import DisplayPumpViewComponent from "@/app/sub-modules/pump/components/display-pump-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const PumpSubModule = (domainId, options = {}) => {
    const id = "pump";
    const moduleId = options.moduleId ?? TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditPumpComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayPumpComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayPumpViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "sanitizer",
                showChangeHistory: options.showChangeHistory ?? true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.pump",
            },
        ],
    });
};
