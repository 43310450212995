import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1e553362"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "dashboard" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_UserGreeting = _resolveComponent("UserGreeting");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_Icon = _resolveComponent("Icon");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_RecentNotifications = _resolveComponent("RecentNotifications");
    const _component_OtherResources = _resolveComponent("OtherResources");
    const _component_Panel = _resolveComponent("Panel");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeader, null, {
            title: _withCtx(() => [
                _createVNode(_component_UserGreeting)
            ]),
            buttons: _withCtx(() => [
                (_ctx.roleCheck(_ctx.$permissions.isLoadyAdmin))
                    ? (_openBlock(), _createBlock(_component_AppButton, {
                        key: 0,
                        onClick: _ctx.createUserModal.open
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("dashboard.components.create-user.button")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                (_ctx.roleCheck(_ctx.$permissions.canManageTenders) && !_ctx.isLogisticsServiceProvider)
                    ? (_openBlock(), _createBlock(_component_AppButton, {
                        key: 1,
                        class: "p-button-secondary",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.navigateToTenders()))
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Icon, { name: "svg/tenders" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.translate("dashboard.components.tender.button")), 1)
                        ]),
                        _: 1
                    }))
                    : (!_ctx.isLogisticsServiceProvider)
                        ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                            key: 2,
                            class: "active-events",
                            disabled: true
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_Icon, { name: "svg/tenders-dark" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate("dashboard.components.tender.button")), 1)
                            ]),
                            _: 1
                        })), [
                            [
                                _directive_tooltip,
                                _ctx.translate('dashboard.components.tender.tooltip'),
                                void 0,
                                { bottom: true }
                            ]
                        ])
                        : _createCommentVNode("", true)
            ]),
            _: 1
        }),
        _createElementVNode("div", null, [
            _createVNode(_component_Panel, { class: "mb-4" }, {
                default: _withCtx(() => [
                    _createVNode(_component_RecentNotifications),
                    _createVNode(_component_OtherResources)
                ]),
                _: 1
            })
        ])
    ]));
}
